#fp-nav  {
	@media screen and (max-width: 768px) {
		display: none;
	}
	&.right {
		right: 38px;
		width: 50px;
	}
	ul {
		li {
			margin: 0px;
			width: 100%;
			height: auto;
			text-align: right;
			display: block;
			float: right;

			.fp-tooltip.right {
				color: #ffffff;
				font-family: Dosis;
				font-size: 14px;
				font-weight: 700;
				line-height: 22px;
				position: absolute;
				margin-left: 50px;
				right: 66px;
				top: 5px;

				.menu-opened & {
					width: auto;
					opacity: 1;
				}
			}
			a {
				display: block;
				width: 27px;
				padding: 15px 0px;
				height: auto;
				margin: 7px;
				position: relative;
				margin: 0px;
				float: right;

				.menu-opened & {
					width: 50px;
				}

				span {
					width: 100%;
					height: 2px;
					background-color: #000000;
					left: auto;
					right: 0px;
					top: 0;
					right: 0px;
					border-radius: 0;
					margin: 0px;
					display: block;
					float: right;
					position: relative;

					.menu-opened & {
						background-color: #fff;
					}
				}

				&.active {
					width: 50px;
					span {
						width: 100%;
						height: 2px;
						background-color: #000000;
						left: auto;
						right: 0px;
						top: 0;
						right: 0px;
						border-radius: 0;
						margin: 0px;
						display: block;
						float: right;
						position: relative;

						.menu-opened & {
							background-color: #fff;
						}
					}
				}

			}

			&:hover {
				margin: 0px;
				width: 100%;
				a {
					width: 50px;
					padding: 15px 0px;
					height: auto;

					span {
						width: 100%;
						height: 2px;
						background-color: #000000;
						left: auto;
						right: 0px;
						top: 0;
						right: 0px;
						border-radius: 0;
						margin: 0px;
						display: block;
						float: right;
						position: relative;

						.menu-opened & {
							background-color: #fff;
						}
					}

					&.active {
						width: 50px;
						span {
							width: 100%;
							height: 2px;
							background-color: #000000;
							left: auto;
							right: 0px;
							top: 0;
							right: 0px;
							border-radius: 0;
							margin: 0px;
							display: block;
							float: right;
							position: relative;

							.menu-opened & {
								background-color: #fff;
							}
						}
					}
				}
			}
		}
    }
}