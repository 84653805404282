body {


	&[class^='fp-viewing-uvod'], &[class^='fp-viewing-intro'] {
		#fp-nav, .header, .header .header__menubutton > p {
		   visibility: hidden;
		   opacity: 0;
		   transition: all 0.2s linear;
		}

		.fp-slidesNav {
			display: none;
		}
	}

	a {
	    color: #4467c3;
	    transition: all 0.2s linear;

	    &:focus, &:hover, &:active {
	        outline: 0;
	        color: inherit;
	    }
 
	}

	li { list-style: none; }
	
	::selection {
	    color: $color-white;
	    background: $color-black;
	    text-shadow: none;
	}
	::-webkit-selection {
	    color: $color-white;
	    background: $color-black;
	    text-shadow: none;
	}
	::-moz-selection {
	    color: $color-white;
	    background: $color-black;
	    text-shadow: none;
	}

	:active, :focus { outline: none; }
	

	.container {
		width: 1170px;
		max-width: 100%;
	}

	main {
		float: left;
		width: 100%;
		position: relative;
	}
}