.fiber {
	display: flex;
	align-items: center;
	flex-direction: column;

	* {
		transition: all 0.2s linear;
	}

	.fp-slidesNav, .fp-next, .fp-prev {
		display: none;
	}

	&__slide {
		position: relative;

		.container {
			z-index: 1;
		}

		&:before {
			content: "";
			width: 50%;
			position: absolute;
			top: 0px;
			left: 0px;
			height: 100%;
			z-index: 0;
		}

		&:after {
			content: "";
			position: absolute;
			width: 50%;
			top: 0px;
			right: 0px;
			height: 100%;
			z-index: 0;
		}

		&--blue-pink {
			&:before {
				background: #2a7197;
			}

			&:after {
				background: #977685;
			}
		}

		&--red-brown {
			&:before {
				background: #a62a2a;
			}

			&:after {
				background: #9a5a2a;
			} 
		}

		&--pink-green {
			&:before {
				background: #aa2a51;
			}
			&:after {
				background: #7e912a;
			} 


		}

		&--all-blue {
			&:before, &:after {
				background: #4c6080;
			}
		}
	}

	&__content {

		width: 100%;
		max-width: 506px;
		display: block;
		float: left;
		height: 100%;
		transition: all 0.2s linear;

		&--left {
			float: left;
		}
		&--right {
			float: right;
		}
	}

	&__icon {
		display: block;
		float: left;
		width: 100%;
		margin-bottom: 45px;

		img {
			max-width: 100%;
		}
	}

	&__title {
		display: block;
		float: left;
		width: 100%;
		margin-bottom: 35px;
		* {
			font-family: $font-1;
			color: #fff;
			font-size: 32px;
			font-weight: 700;
		}
	}

	&__text {
		display: block;
		float: left;
		width: 100%;
		max-height: 9999px;
		overflow-y: hidden;
		position: relative;
		transition: all 0.2s linear;

		.fiber__content--overflow & {
			height: 200px;
			transition: all 0.2s linear;
		}
		
		* {
			color: #ffffff;
			font-family: $font-2;
			font-size: 14px;
			font-weight: 400;
			line-height: 28px;
		}


		.fiber__slide--pink-green .fiber__content--left & {

			&:before {
				content: "";
				display: block;
				position: absolute;
				bottom: 0px;
				height: 100%;
				width: 100%;
			    box-shadow: 0px;
			    transition: all 0.2s linear;
			}
		}

		.fiber__slide--pink-green .fiber__content--left.fiber__content--overflow & {

			&:before {
			    box-shadow: inset 0px -70px 70px -30px #aa2a51;
			    transition: all 0.2s linear;
			}
		}

		.fiber__slide--pink-green .fiber__content--right & {

			&:before {
				content: "";
				display: block;
				position: absolute;
				bottom: 0px;
				height: 100%;
				width: 100%;
			    box-shadow: 0px;
			    transition: all 0.2s linear;
			}
		}

		.fiber__slide--pink-green .fiber__content--right.fiber__content--overflow & {
			&:before {
			    box-shadow: inset 0px -70px 70px -30px #7e912a;
			    transition: all 0.2s linear;
			}
		}
	}

	&__nav {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 92px;
		z-index: 99;
		display: block;
		float: left;
		width: 100%;
		text-align: center;

		ul {
			margin: 0;
			padding: 0;
			display: block;
			float: left;
			width: 100%;
			text-align: center;
		}
	}
	&__item {
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		border: 1px solid #000000;
		margin: 0px 5px;
		cursor: pointer;
		background: transparent;
		transition: all 0.2s linear;

		&--active {
			background: #000;
		}

		&--prev {
			width: auto;
			border: 0;
			height: auto;
			margin-left: 0px;
			margin-right: 18px;
		}

		&--next {
			width: auto;
			border: 0;
			height: auto;
			margin-right: 0px;
			margin-left: 18px;
		}

		&--hidden {
			visibility: hidden;
			opacity: 0;
		}
	}

	&__button {
		display: block;
		float: left;
		width: 100%;
		margin-top: 30px;
		margin-bottom: 30px;
		text-align: center;

		button {
			color: #ffffff;
			font-family: $font-1;
			font-size: 14px;
			font-weight: 700;
			line-height: 28px;
			text-transform: uppercase;
			position: relative;
			display: inline-block;
			padding-right: 10px;
			background: transparent;
			border: 0px;
			cursor: pointer;

			&:hover {
				text-decoration: none;
				color: #fff;
			}

			&:before {
				content: "";
				display: inline-block;
				background-image: url("../images/icon-plus.svg");
				width: 10px;
				height: 10px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin: 0px 12px;
				position: relative;
				z-index: 1;
			}

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 50%;
				bottom: 0px;
				background-color: #a7ce00;
				opacity: 0.15;
				left: 0px;
			}

			.fiber__slide--pink-green .fiber__content--left & {

				&:after {
					background-color: #ff004c;
				}
			}

			.fiber__slide--pink-green .fiber__content--right & {

				&:after {
					background-color: #a7ce00;
				}
			}
		}
	}
}