// COLORS (note: after change update modules/colors)
$color-white:			#FFFFFF;
$color-black:			#000000;
$yellow: 				#ead893;
$text-dark: 			#5f4536;
$text-light: 			#e9e6d9;
$text-highlight: 		#e25d43;
$ribbon-text-color: 	#fcf9ee;

// BREAKPOINTS
$screen-xxs-min:		rem(320px);
$screen-xs-min:			rem(480px);
$screen-sm-min:			rem(768px);
$screen-md-min:			rem(992px);
$screen-lg-min:			rem(1200px);

$screen-xxs-max:		($screen-xxs-min - 1);
$screen-xs-max:			($screen-xs-min - 1);
$screen-sm-max:			($screen-sm-min - 1);
$screen-md-max:			($screen-md-min - 1);
$screen-lg-max:			($screen-lg-min - 1);
