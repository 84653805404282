@charset "UTF-8";

/*		PX to Rem */

@import './bower_components/sass-rem/rem.scss';

/*		ABSTRACTS
		- for all settings, helpers, functions etc. No direct output. */

@import 'abstracts/fonts';
@import 'abstracts/mixins';
@import 'abstracts/variables';


/*		BASE
		- Global styles of our project. 
			And helpers - custom classes. */

@import 'base/global';
@import 'base/typography';

/*		COMPONENTS
		- All components of our webpage. */
@import 'components/introduction';
@import 'components/karpathia';
@import 'components/navigation';
@import 'components/main-menu';
@import 'components/fiber';
@import 'components/products';
@import 'components/contact';
@import 'components/mail-loader';
@import 'components/write-us';



//@import 'components/calculator/box';

/*		LAYOUTS
		- Anything that is a part of our complete layout. */

@import 'layout/footer';
@import 'layout/header';


/*		PLUGINS
		Customizations of front-end dependencies & plugins. */

