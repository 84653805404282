.main-menu {
	position: absolute;
	z-index: 99999;
	width: 100%;
	height: 100%;
	background:  #353535;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	max-height: rem(0px);
	height: 100%;
	transition: all 0.2s linear;

	.menu-opened & {
		opacity: 1;
		max-height: rem(9999px);
		visibility: visible;
		height: 100%;

		@media screen and (max-width: rem(768px)) {
			height: 100vh;
		}
	}

	&__language {
		position: absolute;
		top: 0px;
		left: 0px;
		margin-top: 45px;
		margin-left: 50px;

		ul {
			padding: 0;
			margin-bottom: 0;

			li {
				display: block;
				padding: 0px 10px;
				float: left;

				&:first-child {
					border-right: 1px solid #fff;
				}

				a {
					color: #fff;

					&:hover, &:focus {
						text-decoration: underline;
						color: #fff;
					}
				}

				&.language__active {
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__copyright {
		margin-bottom: 45px;
	    margin-left: 50px;

	    @media screen and (max-width: rem(768px)) {
	    	margin-bottom: 15px;
    	    margin-left: 0px;
    	    position: absolute;
    	    bottom: 50px;
    	    left: 15px;
	    }
		p {
			color: #ffffff;
			font-family: $font-1;
			font-size: 14px;
			font-weight: 700;
			line-height: 22px;
			display: inline-block;
			margin-bottom: 0px;

			&:first-child {
				&:after {
					content: "";
					display: inline-block;
					width: 50px;
					height: 2px;
					position: relative;
					background: #fff;
					top: -3px;
					margin-left: 16px;
					margin-right: 16px;
				}
			}

			 a {
			 	color: #fff;
			 	&:hover, &:focus {
			 		color: #fff;
			 		text-decoration: underline;
			 	}
			 }
		}
	}

	&__social {
		margin-bottom: 45px;
	    margin-right: 50px;
		p {
			margin-right: 20px;
			color: #ffffff;
			font-family: $font-1;
			font-size: 14px;
			font-weight: 700;
			line-height: 22px;
			display: inline-block;
			margin-bottom: 0px;

		}
	}

	&__icons {
		display: inline-block;

		a {
			margin: 6px;

			&:last-child {
				margin-right: 0px;
			}

			&:hover, &:focus {
				text-decoration: none;
			}
		}
	}
}