.header {
	position: fixed;
	z-index: 9;
	display: block;
	width: 100%;
	visibility: visible;
	opacity: 1;
	height: 0px;
	animation: headerAnimation;

	&.menu-opened {
		height: 100%;
	}

	&__logo {
		position: relative;
		display: block;
		float: left;
		margin-top: 30px;
		margin-left: 30px;

		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	&__menubutton {
		position: relative;
		float: right;
		display: block;
		margin-top: 30px;
		margin-right: 30px;

	    @media screen and (max-width: 768px) {
			margin-top: 15px;
			margin-right: 15px; 
	    }

		.menu-opened & {
			z-index: 999999;
		}

		p {

			color: #ffffff;
			font-family: $font-1;
			font-size: 14px;
			font-weight: 700;
			line-height: 22px;
		    cursor: pointer;
    		margin-right: 20px;
		    position: relative;
    		top: 17px;
		    float: left;
		    opacity: 1;
		    visibility: visible;
		    transition: all 0.2s linear;

		    @media screen and (max-width: 768px) {
				font-size: 10px;
				line-height: 22px;
				margin-right: 5px; 
		    }

		    &.changing {
		    	opacity: 0;
		    }

			.menu-opened & {
				opacity: 1;
			}

		}
		.toggle {
			display: block;
			float: left;
			width: 70px;
			height: 54px;
			background-color: #333333;
			cursor: pointer;
			position: relative;
			span {

				display: block;
				position: absolute;
				height: 2px;
				width: 100%;
				background: #fff;
				border-radius: rem(0px);
				opacity: 1;
				width: 30px;
				left: 50%;
				margin-left: -15px;
				transform: rotate(0deg);
				transition: .25s ease-in-out;
			}

			span:nth-child(1) {
 				top: 18px;
			}

			span:nth-child(2), span:nth-child(3) {
  				top: 26px;
			}

			span:nth-child(4) {
  				top: 34px;
			}


			&.open span:nth-child(1) {
				top: rem(30px);
				width: 0%;
				left: 50%;
				margin-left: 0;
				background: #fff;
			}

			&.open span:nth-child(2) {
			  	transform: rotate(45deg);
			  	background: #fff;
			}

			&.open span:nth-child(3) {
			 	transform: rotate(-45deg);
			 	background: #fff;
			}

			&.open span:nth-child(4) {
				top: rem(30px);
				width: 0%;
				left: 50%;
				margin-left: rem(0px);
				background: #fff;
			}
		}
	}
}


@keyframes headerAnimation {
    0%   {background-color: red;}
    25%  {background-color: yellow;}
    50%  {background-color: blue;}
    100% {background-color: green;}
}