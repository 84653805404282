.karpathia {
	background-image: url("../images/bg-karpathia.png");
	background-repeat: no-repeat;
	background-position: center;
	background-color: #353535;
	background-size: cover;
	display: flex;
	align-items: center;
	flex-direction: column;


	&__logo {
		display: block;
		float: left;
		width: 100%;
		margin-bottom: 50px;

		img {
			max-width: 100%;
		}
	}

	&__text {
		display: block;
		float: left;
		width: 100%;

		color: #ffffff;
		font-family: $font-2;
		font-size: 16px;
		font-weight: 400;
		line-height: 28px;
	}
}