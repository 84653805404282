/*		PX to Rem */
/*		ABSTRACTS
		- for all settings, helpers, functions etc. No direct output. */
/*

List of mixins
    @include clearfix;
    @include placeholder($color-base);

*/
/* eg: @include user-select(none); */
/*		BASE
		- Global styles of our project. 
			And helpers - custom classes. */
body[class^='fp-viewing-uvod'] #fp-nav, body[class^='fp-viewing-uvod'] .header, body[class^='fp-viewing-uvod'] .header .header__menubutton > p, body[class^='fp-viewing-intro'] #fp-nav, body[class^='fp-viewing-intro'] .header, body[class^='fp-viewing-intro'] .header .header__menubutton > p {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s linear;
}

body[class^='fp-viewing-uvod'] .fp-slidesNav, body[class^='fp-viewing-intro'] .fp-slidesNav {
  display: none;
}

body a {
  color: #4467c3;
  transition: all 0.2s linear;
}

body a:focus, body a:hover, body a:active {
  outline: 0;
  color: inherit;
}

body li {
  list-style: none;
}

body ::selection {
  color: #FFFFFF;
  background: #000000;
  text-shadow: none;
}

body ::-webkit-selection {
  color: #FFFFFF;
  background: #000000;
  text-shadow: none;
}

body ::-moz-selection {
  color: #FFFFFF;
  background: #000000;
  text-shadow: none;
}

body :active, body :focus {
  outline: none;
}

body .container {
  width: 1170px;
  max-width: 100%;
}

body main {
  float: left;
  width: 100%;
  position: relative;
}

html {
  font-size: 100%;
}

@media screen and (max-width: 61.9375rem) {
  html {
    font-size: 80% !important;
  }
}

@media screen and (max-width: 48rem) {
  html {
    font-size: 60% !important;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
}

body b, body strong {
  font-weight: bold;
}

body em {
  font-style: italic;
}

body p {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0rem;
  font-family: "Dosis", sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

/*		COMPONENTS
		- All components of our webpage. */
.introduction {
  background-image: url("../images/bg-introduction.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #353535;
}

.introduction:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 53, 53, 0.9);
}

.introduction .slide {
  position: relative;
  overflow: hidden;
}

.introduction .fp-prev, .introduction .fp-next {
  display: none;
}

.introduction video {
  position: absolute;
  top: 0px;
  display: block;
  left: 0px;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 767px) {
  .introduction video {
    object-fit: cover;
  }
}

.introduction__logo {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
}

.introduction__logo img {
  max-width: 100%;
}

.introduction__button {
  display: block;
  float: left;
  width: 100%;
  position: absolute;
  bottom: 100px;
  text-align: center;
}

.introduction__button a {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-right: 10px;
}

.introduction__button a:hover, .introduction__button a:focus {
  text-decoration: none;
  color: #fff;
}

.introduction__button a:before {
  content: "";
  display: inline-block;
  background-image: url("../images/icon-plus.svg");
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0px 12px;
  position: relative;
  z-index: 1;
}

.introduction__button a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  background-color: #ffffff;
  opacity: 0.15;
  left: 0px;
}

.karpathia {
  background-image: url("../images/bg-karpathia.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #353535;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.karpathia__logo {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 50px;
}

.karpathia__logo img {
  max-width: 100%;
}

.karpathia__text {
  display: block;
  float: left;
  width: 100%;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

@media screen and (max-width: 768px) {
  #fp-nav {
    display: none;
  }
}

#fp-nav.right {
  right: 38px;
  width: 50px;
}

#fp-nav ul li {
  margin: 0px;
  width: 100%;
  height: auto;
  text-align: right;
  display: block;
  float: right;
}

#fp-nav ul li .fp-tooltip.right {
  color: #ffffff;
  font-family: Dosis;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  position: absolute;
  margin-left: 50px;
  right: 66px;
  top: 5px;
}

.menu-opened #fp-nav ul li .fp-tooltip.right {
  width: auto;
  opacity: 1;
}

#fp-nav ul li a {
  display: block;
  width: 27px;
  padding: 15px 0px;
  height: auto;
  margin: 7px;
  position: relative;
  margin: 0px;
  float: right;
}

.menu-opened #fp-nav ul li a {
  width: 50px;
}

#fp-nav ul li a span {
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: auto;
  right: 0px;
  top: 0;
  right: 0px;
  border-radius: 0;
  margin: 0px;
  display: block;
  float: right;
  position: relative;
}

.menu-opened #fp-nav ul li a span {
  background-color: #fff;
}

#fp-nav ul li a.active {
  width: 50px;
}

#fp-nav ul li a.active span {
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: auto;
  right: 0px;
  top: 0;
  right: 0px;
  border-radius: 0;
  margin: 0px;
  display: block;
  float: right;
  position: relative;
}

.menu-opened #fp-nav ul li a.active span {
  background-color: #fff;
}

#fp-nav ul li:hover {
  margin: 0px;
  width: 100%;
}

#fp-nav ul li:hover a {
  width: 50px;
  padding: 15px 0px;
  height: auto;
}

#fp-nav ul li:hover a span {
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: auto;
  right: 0px;
  top: 0;
  right: 0px;
  border-radius: 0;
  margin: 0px;
  display: block;
  float: right;
  position: relative;
}

.menu-opened #fp-nav ul li:hover a span {
  background-color: #fff;
}

#fp-nav ul li:hover a.active {
  width: 50px;
}

#fp-nav ul li:hover a.active span {
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: auto;
  right: 0px;
  top: 0;
  right: 0px;
  border-radius: 0;
  margin: 0px;
  display: block;
  float: right;
  position: relative;
}

.menu-opened #fp-nav ul li:hover a.active span {
  background-color: #fff;
}

.main-menu {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: #353535;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  max-height: 0rem;
  height: 100%;
  transition: all 0.2s linear;
}

.menu-opened .main-menu {
  opacity: 1;
  max-height: 624.9375rem;
  visibility: visible;
  height: 100%;
}

@media screen and (max-width: 48rem) {
  .menu-opened .main-menu {
    height: 100vh;
  }
}

.main-menu__language {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 45px;
  margin-left: 50px;
}

.main-menu__language ul {
  padding: 0;
  margin-bottom: 0;
}

.main-menu__language ul li {
  display: block;
  padding: 0px 10px;
  float: left;
}

.main-menu__language ul li:first-child {
  border-right: 1px solid #fff;
}

.main-menu__language ul li a {
  color: #fff;
}

.main-menu__language ul li a:hover, .main-menu__language ul li a:focus {
  text-decoration: underline;
  color: #fff;
}

.main-menu__language ul li.language__active a {
  text-decoration: underline;
}

.main-menu__copyright {
  margin-bottom: 45px;
  margin-left: 50px;
}

@media screen and (max-width: 48rem) {
  .main-menu__copyright {
    margin-bottom: 15px;
    margin-left: 0px;
    position: absolute;
    bottom: 50px;
    left: 15px;
  }
}

.main-menu__copyright p {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
  margin-bottom: 0px;
}

.main-menu__copyright p:first-child:after {
  content: "";
  display: inline-block;
  width: 50px;
  height: 2px;
  position: relative;
  background: #fff;
  top: -3px;
  margin-left: 16px;
  margin-right: 16px;
}

.main-menu__copyright p a {
  color: #fff;
}

.main-menu__copyright p a:hover, .main-menu__copyright p a:focus {
  color: #fff;
  text-decoration: underline;
}

.main-menu__social {
  margin-bottom: 45px;
  margin-right: 50px;
}

.main-menu__social p {
  margin-right: 20px;
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
  margin-bottom: 0px;
}

.main-menu__icons {
  display: inline-block;
}

.main-menu__icons a {
  margin: 6px;
}

.main-menu__icons a:last-child {
  margin-right: 0px;
}

.main-menu__icons a:hover, .main-menu__icons a:focus {
  text-decoration: none;
}

.fiber {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fiber * {
  transition: all 0.2s linear;
}

.fiber .fp-slidesNav, .fiber .fp-next, .fiber .fp-prev {
  display: none;
}

.fiber__slide {
  position: relative;
}

.fiber__slide .container {
  z-index: 1;
}

.fiber__slide:before {
  content: "";
  width: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 0;
}

.fiber__slide:after {
  content: "";
  position: absolute;
  width: 50%;
  top: 0px;
  right: 0px;
  height: 100%;
  z-index: 0;
}

.fiber__slide--blue-pink:before {
  background: #2a7197;
}

.fiber__slide--blue-pink:after {
  background: #977685;
}

.fiber__slide--red-brown:before {
  background: #a62a2a;
}

.fiber__slide--red-brown:after {
  background: #9a5a2a;
}

.fiber__slide--pink-green:before {
  background: #aa2a51;
}

.fiber__slide--pink-green:after {
  background: #7e912a;
}

.fiber__slide--all-blue:before, .fiber__slide--all-blue:after {
  background: #4c6080;
}

.fiber__content {
  width: 100%;
  max-width: 506px;
  display: block;
  float: left;
  height: 100%;
  transition: all 0.2s linear;
}

.fiber__content--left {
  float: left;
}

.fiber__content--right {
  float: right;
}

.fiber__icon {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 45px;
}

.fiber__icon img {
  max-width: 100%;
}

.fiber__title {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 35px;
}

.fiber__title * {
  font-family: "Dosis", sans-serif;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}

.fiber__text {
  display: block;
  float: left;
  width: 100%;
  max-height: 9999px;
  overflow-y: hidden;
  position: relative;
  transition: all 0.2s linear;
}

.fiber__content--overflow .fiber__text {
  height: 200px;
  transition: all 0.2s linear;
}

.fiber__text * {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}

.fiber__slide--pink-green .fiber__content--left .fiber__text:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 100%;
  box-shadow: 0px;
  transition: all 0.2s linear;
}

.fiber__slide--pink-green .fiber__content--left.fiber__content--overflow .fiber__text:before {
  box-shadow: inset 0px -70px 70px -30px #aa2a51;
  transition: all 0.2s linear;
}

.fiber__slide--pink-green .fiber__content--right .fiber__text:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 100%;
  box-shadow: 0px;
  transition: all 0.2s linear;
}

.fiber__slide--pink-green .fiber__content--right.fiber__content--overflow .fiber__text:before {
  box-shadow: inset 0px -70px 70px -30px #7e912a;
  transition: all 0.2s linear;
}

.fiber__nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 92px;
  z-index: 99;
  display: block;
  float: left;
  width: 100%;
  text-align: center;
}

.fiber__nav ul {
  margin: 0;
  padding: 0;
  display: block;
  float: left;
  width: 100%;
  text-align: center;
}

.fiber__item {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border: 1px solid #000000;
  margin: 0px 5px;
  cursor: pointer;
  background: transparent;
  transition: all 0.2s linear;
}

.fiber__item--active {
  background: #000;
}

.fiber__item--prev {
  width: auto;
  border: 0;
  height: auto;
  margin-left: 0px;
  margin-right: 18px;
}

.fiber__item--next {
  width: auto;
  border: 0;
  height: auto;
  margin-right: 0px;
  margin-left: 18px;
}

.fiber__item--hidden {
  visibility: hidden;
  opacity: 0;
}

.fiber__button {
  display: block;
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.fiber__button button {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-right: 10px;
  background: transparent;
  border: 0px;
  cursor: pointer;
}

.fiber__button button:hover {
  text-decoration: none;
  color: #fff;
}

.fiber__button button:before {
  content: "";
  display: inline-block;
  background-image: url("../images/icon-plus.svg");
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0px 12px;
  position: relative;
  z-index: 1;
}

.fiber__button button:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  background-color: #a7ce00;
  opacity: 0.15;
  left: 0px;
}

.fiber__slide--pink-green .fiber__content--left .fiber__button button:after {
  background-color: #ff004c;
}

.fiber__slide--pink-green .fiber__content--right .fiber__button button:after {
  background-color: #a7ce00;
}

.products {
  background: #353535;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.products__title {
  display: block;
  float: left;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: "Dosis", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 90px;
}

.products__image {
  display: block;
  float: left;
  width: 100%;
  text-align: right;
  padding-right: 27px;
}

.products__image img {
  max-width: 100%;
}

.products__list {
  display: block;
  float: left;
  width: 100%;
}

.products__item {
  display: block;
  float: left;
  width: 100%;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 27.98px;
  padding-left: 26px;
  position: relative;
  margin-bottom: 3px;
}

.products__item:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -5px;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #bf2991;
}

.products__item:nth-child(1):before {
  background-color: #bf2991;
}

.products__item:nth-child(2):before {
  background-color: #ee3633;
}

.products__item:nth-child(3):before {
  background-color: #3a9b3f;
}

.products__item:nth-child(4):before {
  background-color: #d9b62b;
}

.products__item:nth-child(5):before {
  background-color: #57c6cc;
}

.products__item:nth-child(6):before {
  background-color: #c7c7c7;
}

.products__item:nth-child(7):before {
  background-color: #d9da67;
}

.products__item:nth-child(8):before {
  background-color: #9e1830;
}

.products__item:nth-child(9):before {
  background-color: #7c6aaf;
}

.products__item:nth-child(10):before {
  background-color: #d07c2b;
}

.products__item:nth-child(11):before {
  background-color: #a457a2;
}

.products__item:nth-child(12):before {
  background-color: #74c045;
}

.products__item:nth-child(13):before {
  background-color: #2c2786;
}

.products__button {
  display: block;
  float: left;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;
}

.products__button a {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-right: 10px;
}

.products__button a:hover {
  text-decoration: none;
  color: #fff;
}

.products__button a:before {
  content: "";
  display: inline-block;
  background-image: url("../images/icon-plus.svg");
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0px 12px;
  position: relative;
  z-index: 1;
}

.products__button a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  background-color: #ffffff;
  opacity: 0.15;
  left: 0px;
}

.contact {
  background: #353535;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact:before {
  content: "";
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  z-index: 0;
  background-image: url("../images/bg-contact.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .contact:before {
    display: none;
  }
}

.contact .slide {
  position: relative;
  overflow: hidden;
}

.contact .slide:nth-child(2):before {
  content: "";
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  z-index: 0;
  background: #555555;
}

@media screen and (max-width: 768px) {
  .contact .slide:nth-child(2):before {
    display: none;
  }
}

.contact .fp-prev, .contact .fp-next {
  display: none;
}

.contact__map {
  position: absolute;
  left: 0px;
  top: 0;
  width: 50%;
}

.contact__map img {
  display: block;
  float: left;
  width: 100%;
}

.contact__address {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 13px;
}

.contact__icon {
  display: block;
  float: left;
  width: 100%;
  position: relative;
  padding-left: 31px;
}

.contact__icon a {
  display: block;
  float: left;
  width: 100%;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}

@media screen and (max-width: 768px) {
  .contact__icon a {
    font-size: 12px;
    line-height: 20px;
  }
}

.contact__icon a:hover, .contact__icon a:focus {
  color: #fff;
}

.contact__icon:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.contact__icon--tel {
  margin-bottom: 28px;
}

.contact__icon--tel:before {
  top: 7px;
  width: 14px;
  height: 14px;
  background-image: url("../images/icon-phone.svg");
}

.contact__icon--email {
  margin-bottom: 28px;
}

.contact__icon--email:before {
  top: 7px;
  width: 15px;
  height: 15px;
  background-image: url("../images/icon-email.svg");
}

.contact__icon--web {
  margin-bottom: 28px;
}

.contact__icon--web:before {
  top: 7px;
  width: 19px;
  height: 15px;
  background-image: url("../images/icon-www.svg");
}

.contact__icon--mobile:before {
  top: 7px;
  width: 16px;
  height: 16px;
  background-image: url("../images/icon-mobile.svg");
}

.contact__title {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 2px;
}

.contact__title h3 {
  font-family: "Dosis", sans-serif;
  font-size: 24px;
  font-weight: 300;
  color: #fff;
}

.contact__padding {
  display: block;
  float: left;
  width: 100%;
  padding-left: 51px;
}

.contact__opening-hours {
  display: block;
  float: left;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 11px;
}

.contact__button {
  display: block;
  float: left;
  width: 100%;
  position: absolute;
  margin-top: 0;
  bottom: 100px;
}

.contact__button a {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 124px;
  left: 50%;
  margin-left: -230px;
}

.contact__button a:hover, .contact__button a:focus {
  text-decoration: none;
  color: #fff;
}

.contact__button a:before {
  content: "";
  display: inline-block;
  background-image: url("../images/arrow-right.svg");
  width: 52px;
  height: 17px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0px 12px;
  position: relative;
  z-index: 1;
  float: right;
  position: absolute;
  right: -87px;
  top: 10px;
}

.contact__button a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  background-color: #ffffff;
  opacity: 0.15;
  left: 0px;
}

.contact__button--second a {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 124px;
  position: relative;
  left: unset;
  margin-left: auto;
  position: absolute;
  margin-right: -213px;
  right: 50%;
}

.contact__button--second a:hover, .contact__button--second a:focus {
  text-decoration: none;
  color: #fff;
}

.contact__button--second a:before {
  content: "";
  display: inline-block;
  background-image: url("../images/arrow-left.svg");
  width: 52px;
  height: 17px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0px 12px;
  position: relative;
  z-index: 1;
  float: left;
  position: absolute;
  left: -87px;
  top: 10px;
}

.contact__button--second a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  background-color: #ffffff;
  opacity: 0.15;
  left: 0px;
}

.mail-loader {
  display: none;
  float: left;
  width: 100%;
  position: relative;
}

.mail-loader.visible {
  display: block;
}

.mail-loader .sk-circle {
  margin: 30px auto 0px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.mail-loader .sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.mail-loader .sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #090a25;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.mail-loader .sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.mail-loader .sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.mail-loader .sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mail-loader .sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.mail-loader .sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.mail-loader .sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mail-loader .sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.mail-loader .sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.mail-loader .sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mail-loader .sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.mail-loader .sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.mail-loader .sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.mail-loader .sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.mail-loader .sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.mail-loader .sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.mail-loader .sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.mail-loader .sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.mail-loader .sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.mail-loader .sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.mail-loader .sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.mail-loader .sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.mail-loader .sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.write-us {
  background-image: url("../images/bg-karpathia.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #353535;
}

.write-us__title {
  color: #000000;
  font-family: "Dosis", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 44px;
}

.write-us .form-group {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.write-us .form-group label {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: #000000;
  display: block;
  float: left;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 1px;
}

.write-us .form-group input {
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: block;
  float: left;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;
  transition: all 0.2s linear;
}

.write-us .form-group input:focus {
  border-bottom: 1px solid #000;
}

.write-us .form-group textarea {
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: block;
  float: left;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;
  height: 100%;
  max-height: 324px;
  line-height: 32px;
  padding-top: 2px;
}

.write-us .form-group textarea:focus {
  border-bottom: 1px solid #000;
}

.write-us__left {
  background-color: #ffffff;
  display: block;
  float: left;
  width: 100%;
  padding: 45px 48px 50px 52px;
}

.write-us__right {
  background-color: #ffffff;
  display: block;
  float: left;
  width: 100%;
  padding: 45px 48px 50px 52px;
  height: 100%;
}

.write-us__submit {
  display: block;
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 63px;
}

.write-us__submit button {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
  background: transparent;
  border: 0px;
  position: relative;
  padding: 0px;
  display: inline-block;
  padding-right: 10px;
  cursor: pointer;
}

.write-us__submit button:hover {
  text-decoration: none;
  color: #fff;
}

.write-us__submit button:before {
  content: "";
  display: inline-block;
  background-image: url("../images/icon-send.svg");
  width: 15px;
  height: 14px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0px 9px 0px 9px;
  position: relative;
  z-index: 1;
}

.write-us__submit button:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  background-color: #000;
  opacity: 0.15;
  left: 0px;
}

.write-us__results {
  display: block;
  float: left;
  width: 100%;
}

.write-us__results .success {
  margin-top: 30px;
  display: block;
  float: left;
  width: 100%;
  color: #ffffff;
  text-align: center;
  border: 2px solid;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  background: rgba(52, 195, 28, 0.5);
}

@media screen and (max-width: 47rem) {
  .write-us__results .success {
    font-size: 12px;
  }
}

.write-us__results .error {
  margin-top: 30px;
  display: block;
  float: left;
  width: 100%;
  color: #ffffff;
  text-align: center;
  border: 2px solid;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  background: rgba(195, 28, 28, 0.5);
}

@media screen and (max-width: 47rem) {
  .write-us__results .error {
    font-size: 12px;
  }
}

/*		LAYOUTS
		- Anything that is a part of our complete layout. */
.header {
  position: fixed;
  z-index: 9;
  display: block;
  width: 100%;
  visibility: visible;
  opacity: 1;
  height: 0px;
  animation: headerAnimation;
}

.header.menu-opened {
  height: 100%;
}

.header__logo {
  position: relative;
  display: block;
  float: left;
  margin-top: 30px;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  .header__logo {
    display: none;
  }
}

.header__menubutton {
  position: relative;
  float: right;
  display: block;
  margin-top: 30px;
  margin-right: 30px;
}

@media screen and (max-width: 768px) {
  .header__menubutton {
    margin-top: 15px;
    margin-right: 15px;
  }
}

.menu-opened .header__menubutton {
  z-index: 999999;
}

.header__menubutton p {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
  top: 17px;
  float: left;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s linear;
}

@media screen and (max-width: 768px) {
  .header__menubutton p {
    font-size: 10px;
    line-height: 22px;
    margin-right: 5px;
  }
}

.header__menubutton p.changing {
  opacity: 0;
}

.menu-opened .header__menubutton p {
  opacity: 1;
}

.header__menubutton .toggle {
  display: block;
  float: left;
  width: 70px;
  height: 54px;
  background-color: #333333;
  cursor: pointer;
  position: relative;
}

.header__menubutton .toggle span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 0rem;
  opacity: 1;
  width: 30px;
  left: 50%;
  margin-left: -15px;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.header__menubutton .toggle span:nth-child(1) {
  top: 18px;
}

.header__menubutton .toggle span:nth-child(2), .header__menubutton .toggle span:nth-child(3) {
  top: 26px;
}

.header__menubutton .toggle span:nth-child(4) {
  top: 34px;
}

.header__menubutton .toggle.open span:nth-child(1) {
  top: 1.875rem;
  width: 0%;
  left: 50%;
  margin-left: 0;
  background: #fff;
}

.header__menubutton .toggle.open span:nth-child(2) {
  transform: rotate(45deg);
  background: #fff;
}

.header__menubutton .toggle.open span:nth-child(3) {
  transform: rotate(-45deg);
  background: #fff;
}

.header__menubutton .toggle.open span:nth-child(4) {
  top: 1.875rem;
  width: 0%;
  left: 50%;
  margin-left: 0rem;
  background: #fff;
}

@keyframes headerAnimation {
  0% {
    background-color: red;
  }
  25% {
    background-color: yellow;
  }
  50% {
    background-color: blue;
  }
  100% {
    background-color: green;
  }
}

/*		PLUGINS
		Customizations of front-end dependencies & plugins. */
