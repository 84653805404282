.introduction {
	background-image: url("../images/bg-introduction.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #353535;

	&:before {
		content: "";
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(53, 53, 53, 0.9);
	}

	.slide {
		position: relative;
		overflow: hidden;
	}

	.fp-prev, .fp-next {
		display: none;
	}

	video {
		position: absolute;
		top: 0px;
		display: block;
		left: 0px;
		width: 100%;
		height: 100%;
		@media screen and (min-width: 767px) {
			object-fit: cover;
		}
	}

	&__logo {
		display: block;
		float: left;
		width: 100%;
		margin-bottom: 50px;
		text-align: center;

		img {
			max-width: 100%;
		}
	}

	&__button {
		display: block;
		float: left;
		width: 100%;
		position: absolute;
		bottom: 100px;
		text-align: center;

		a {
			color: #ffffff;
			font-family: $font-1;
			font-size: 14px;
			font-weight: 700;
			line-height: 28px;
			text-transform: uppercase;
			position: relative;
			display: inline-block;
			padding-right: 10px;

			&:hover, &:focus {
				text-decoration: none;
				color: #fff;
			}

			&:before {
				content: "";
				display: inline-block;
				background-image: url("../images/icon-plus.svg");
				width: 10px;
				height: 10px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin: 0px 12px;
				position: relative;
				z-index: 1;
			}

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 50%;
				bottom: 0px;
				background-color: #ffffff;
				opacity: 0.15;
				left: 0px;
			}
		}
	}
}