.products {
	background: #353535;
	display: flex;
	align-items: center;
	flex-direction: column;

	&__title {
		display: block;
		float: left;
		width: 100%;
		text-align: center;
		color: #fff;
		font-family: $font-1;
		font-size: 32px;
		font-weight: 700;
		line-height: 28px;
		margin-bottom: 90px;
	}

	&__image {
		display: block;
		float: left;
		width: 100%;
		text-align: right;
		padding-right: 27px;

		img {
			max-width: 100%;
		}
	}

	&__list {
		display: block;
		float: left;
		width: 100%;
	}

	&__item {
		display: block;
		float: left;
		width: 100%;
		color: #ffffff;
		font-family: $font-2;
		font-size: 12px;
		font-weight: 400;
		line-height: 27.98px;
		padding-left: 26px;
		position: relative;
		margin-bottom: 3px;

		&:before {
			content: "";
			position: absolute;
			left: 0px;
			top: 50%;
			margin-top: -5px;
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: #bf2991;
		}

		&:nth-child(1) {
			&:before {
				background-color: #bf2991;
			}
		}
		&:nth-child(2) {
			&:before {
				background-color: #ee3633;
			}
		}
		&:nth-child(3) {
			&:before {
				background-color: #3a9b3f;
			}
		}
		&:nth-child(4) {
			&:before {
				background-color: #d9b62b;
			}
		}
		&:nth-child(5) {
			&:before {
				background-color: #57c6cc;
			}
		}
		&:nth-child(6) {
			&:before {
				background-color: #c7c7c7;
			}
		}
		&:nth-child(7) {
			&:before {
				background-color: #d9da67;
			}
		}
		&:nth-child(8) {
			&:before {
				background-color: #9e1830;
			}
		}
		&:nth-child(9) {
			&:before {
				background-color: #7c6aaf;
			}
		}
		&:nth-child(10) {
			&:before {
				background-color: #d07c2b;
			}
		}
		&:nth-child(11) {
			&:before {
				background-color: #a457a2;
			}
		}
		&:nth-child(12) {
			&:before {
				background-color: #74c045;
			}
		}
		&:nth-child(13) {
			&:before {
				background-color: #2c2786;
			}
		}
	}

	&__button {
		display: block;
		float: left;
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 100px;

		a {
			color: #ffffff;
			font-family: $font-1;
			font-size: 14px;
			font-weight: 700;
			line-height: 28px;
			text-transform: uppercase;
			position: relative;
			display: inline-block;
			padding-right: 10px;

			&:hover {
				text-decoration: none;
				color: #fff;
			}

			&:before {
				content: "";
				display: inline-block;
				background-image: url("../images/icon-plus.svg");
				width: 10px;
				height: 10px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin: 0px 12px;
				position: relative;
				z-index: 1;
			}

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 50%;
				bottom: 0px;
				background-color: #ffffff;
				opacity: 0.15;
				left: 0px;
			}
		}
	}
}