.contact {
	background: #353535;
	display: flex;
	align-items: center;
	flex-direction: column;

	&:before {
		content: "";
		width: 50%;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		z-index: 0;
		background-image: url("../images/bg-contact.png");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	.slide {
		position: relative;
		overflow: hidden;

		&:nth-child(2) {
			&:before {
				content: "";
				width: 50%;
				position: absolute;
				top: 0px;
				right: 0px;
				height: 100%;
				z-index: 0;
				background: #555555;

				@media screen and (max-width: 768px) {
					display: none;
				}
			}
		}
	}

	.fp-prev, .fp-next {
		display: none;
	}

	&__map {
		position: absolute;
		left: 0px;
		top: 0;
		width: 50%;

		img {
			display: block;
			float: left;
			width: 100%;
		}
	}

	&__address {

		display: block;
		float: left;
		width: 100%;
		margin-bottom: 13px;

		p {

		}
	}

	&__icon {

		display: block;
		float: left;
		width: 100%;
		position: relative;
	    padding-left: 31px;

		a {
			display: block;
			float: left;
			width: 100%;
			color: #ffffff;
			font-family: $font-2;
			font-size: 15px;
			font-weight: 400;
			line-height: 28px;

			@media screen and (max-width: 768px) {
				font-size: 12px;
				line-height: 20px;
			}

			&:hover, &:focus {
				color: #fff;
			}
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 0px;
			top: 0px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		}

		&--tel {
			margin-bottom: 28px;
			&:before {
				top: 7px;
				width: 14px;
				height: 14px;
				background-image: url("../images/icon-phone.svg");
			}
		}

		&--email {
			margin-bottom: 28px;
			&:before {
				top: 7px;
				width: 15px;
				height: 15px;
				background-image: url("../images/icon-email.svg");
			}
		}

		&--web {
			margin-bottom: 28px;
			&:before {
				top: 7px;
				width: 19px;
				height: 15px;
				background-image: url("../images/icon-www.svg"); 
			}
		}

		&--mobile {
			&:before {
				top: 7px;
				width: 16px;
				height: 16px;
				background-image: url("../images/icon-mobile.svg");
			}
		}
	}

	&__title {
		display: block;
		float: left;
		width: 100%;
		margin-bottom: 2px;
		h3 {
			font-family: $font-1;
			font-size: 24px;
			font-weight: 300;
			color: #fff;
		}
	}

	&__padding {
		display: block;
		float: left;
		width: 100%;
		padding-left: 51px;
	}

	&__opening-hours {
		display: block;
		float: left;
		width: 100%;
		margin-top: 28px;
		margin-bottom: 11px;
	}

	
	&__button {
		display: block;
		float: left;
		width: 100%;
		position: absolute;
		margin-top: 0;
		bottom: 100px;

		a {
			color: #ffffff;
			font-family: $font-1;
			font-size: 14px;
			font-weight: 700;
			line-height: 28px;
			text-transform: uppercase;
			position: relative;
			display: inline-block;
			padding-right: 10px;
			padding-left: 10px;
			margin-right: 124px;
			left: 50%;
			margin-left: -230px;

			&:hover, &:focus {
				text-decoration: none;
				color: #fff;
			}

			&:before {
				content: "";
				display: inline-block;
				background-image: url("../images/arrow-right.svg");
				width: 52px;
				height: 17px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin: 0px 12px;
				position: relative;
				z-index: 1;
				float: right;
				position: absolute;
				right: -87px;
				top: 10px;
			}

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 50%;
				bottom: 0px;
				background-color: #ffffff;
				opacity: 0.15;
				left: 0px;
			}
		}

		&--second {
			a {
				color: #ffffff;
				font-family: $font-1;
				font-size: 14px;
				font-weight: 700;
				line-height: 28px;
				text-transform: uppercase;
				position: relative;
				display: inline-block;
				padding-right: 10px;
				padding-left: 10px;
				margin-left: 124px;
				position: relative;
				left: unset; 
				margin-left: auto;
			    position: absolute;
			    margin-right: -213px;
			    right: 50%;



				&:hover, &:focus {
					text-decoration: none;
					color: #fff;
				}

				&:before {
					content: "";
					display: inline-block;
					background-image: url("../images/arrow-left.svg");
					width: 52px;
					height: 17px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					margin: 0px 12px;
					position: relative;
					z-index: 1;
					float: left;
					position: absolute;
					left: -87px;
					top: 10px;
				}

				&:after {
					content: "";
					position: absolute;
					width: 100%;
					height: 50%;
					bottom: 0px;
					background-color: #ffffff;
					opacity: 0.15;
					left: 0px;
				}
			}
		}
	}
	
}