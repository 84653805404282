.write-us {
	background-image: url("../images/bg-karpathia.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #353535;


	&__title {
		color: #000000;
		font-family: $font-1;
		font-size: 24px;
		font-weight: 300;
		line-height: 28px;
		margin-bottom: 44px;
	}

	.form-group {
		display: block;
		float: left;
		width: 100%;
		margin-bottom: 30px;

		label {
			font-family: $font-1;
			font-size: 18px;
			font-weight: 700;
			line-height: 28px;
			color: #000000;
			display: block;
			float: left;
			width: 100%;
			text-transform: uppercase;
			cursor: pointer;
			margin-bottom: 1px;
		}

		input {
			color: #000000;
			font-family: $font-2;
			font-size: 16px;
			font-weight: 400;
			display: block;
			float: left;
			width: 100%;
			border: 0px;
			border-bottom: 1px solid #d9d9d9;
			border-radius: 0px;
			padding-left: 0px;
			padding-right: 0px;
			transition: all 0.2s linear;

			&:focus {
				border-bottom: 1px solid #000;
			}
		}

		textarea {
			color: #000000;
			font-family: $font-2;
			font-size: 16px;
			font-weight: 400;
			display: block;
			float: left;
			width: 100%;
			border: 0px;
			border-bottom: 1px solid #d9d9d9;
			border-radius: 0px;
			padding-left: 0px;
			padding-right: 0px;
			height: 100%;
			max-height: 324px;
			line-height: 32px;
			padding-top: 2px;

			&:focus {
				border-bottom: 1px solid #000;
			}
		}
	}

	&__left {
		background-color: #ffffff;
		display: block;
		float: left;
		width: 100%;
		padding: 45px 48px 50px 52px;
	}

	&__right {
		background-color: #ffffff;
		display: block;
		float: left;
		width: 100%;
		padding: 45px 48px 50px 52px;
		height: 100%;
	}

	&__submit {
		display: block;
		float: left;
		width: 100%;
		text-align: center;
		margin-top: 63px;

		button {
			color: #ffffff;
			font-family: $font-1;
			font-size: 14px;
			font-weight: 700;
			line-height: 25px;
			text-transform: uppercase;
			background: transparent;
			border: 0px;
			position: relative;
			padding: 0px;
			display: inline-block;
			padding-right: 10px;
			cursor: pointer;

			&:hover {
				text-decoration: none;
				color: #fff;
			}

			&:before {
				content: "";
				display: inline-block;
				background-image: url("../images/icon-send.svg");
				width: 15px;
				height: 14px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin: 0px 9px 0px 9px;
				position: relative;
				z-index: 1;
			}

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 50%;
				bottom: 0px;
				background-color: #000;
				opacity: 0.15;
				left: 0px;
			}
		}
	}

	&__results {
		display: block;
		float: left;
		width: 100%;

		.success {
			margin-top: 30px;
			display: block;
			float: left;
			width: 100%;
			color: #ffffff;
			text-align: center;
			border: 2px solid;
			padding: 10px;
			font-weight: bold;
			text-transform: uppercase;
			background: rgba(52, 195, 28, 0.5);

			@media screen and (max-width: $screen-sm-max) {
				font-size: 12px;
			}
		}

		.error {
			margin-top: 30px;
			display: block;
			float: left;
			width: 100%;
			color: #ffffff;
			text-align: center;
			border: 2px solid;
			padding: 10px;
			font-weight: bold;
			text-transform: uppercase;
			background: rgba(195, 28, 28, 0.50);

			@media screen and (max-width: $screen-sm-max) {
				font-size: 12px;
			}
		}
	}
}