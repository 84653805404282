/*

List of mixins
    @include clearfix;
    @include placeholder($color-base);

*/

@mixin clearfix() {
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}


/* eg: @include user-select(none); */
@mixin user-select($user-select) {
    -webkit-user-select: ($user-select);
    -moz-user-select: ($user-select);
    -ms-user-select: ($user-select);
    user-select: ($user-select);
}


@mixin placeholder($color: $input-color-placeholder) {
    &:-moz-placeholder            { color: $color; }
    &::-moz-placeholder           { color: $color; }
    &:-ms-input-placeholder       { color: $color; } // Internet Egplorer 10+
    &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

