html {
   font-size: 100%;

  @media screen and (max-width: rem(991px)) {
        font-size: 80% !important;  
  }

  @media screen and (max-width: rem(768px)) {
        font-size: 60% !important;  
  }
}
body {
   font-family: $font-2;
   font-size: rem(16px);
   font-weight: 400;
   line-height: rem(28px);


  b, strong { font-weight: bold; }
  em { font-style: italic; }
  

  p {
    color: #fff;
    font-family: $font-2;
    font-size: rem(16px);
    font-weight: 400;
    line-height: rem(28px);
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: rem(0px);
  font-family: $font-1;
  font-size: rem(32px);
  font-weight: 700;
}

